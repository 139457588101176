/*---Landing Page----*/
.LandingPage {
  text-align: center;
  height: 100vh;
  /*background-image: url('./assets/pattern.png');*/
  background-color: #FFFFFF;
}

::-ms-reveal {
  display: none;
}

.initialText {
  font-size: 3vh;
}

.mainLogo {
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.topLogo {
  font-family: 'Daydream';
  font-size: 7vh;
  text-shadow: rgb(5, 5, 5) 0px 0px 5px;
}

.mainLogoNames {
  font-family: 'Daydream';
  font-size: 9vh;
}

.mainLogoAnd {
  font-family: 'Anaheim';
  font-size: 5vh;
  font-style: italic;
}

.smallLogo {
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 30%;
  font-family: 'Alex Brush';
  font-size: 4vh;
}

.mainDate {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.mainDateInvitation {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.dateDay {
  padding-left: 10px;
  padding-right: 10px;
  border-left: 3px solid #9D7F63;
  border-right: 3px solid #9D7F63;
  font-family: 'PT Serif';
}

.dateMonth {
  padding-right: 10px;
  font-family: 'PT Serif';

}

.dateYear {
  padding-left: 10px;
  font-family: 'PT Serif';
}

.dateDaySize {
  font-size: 9vh;
}

.dateGnrlSize {
  font-size: 5vh;
}

.dateDaySizeInvitation {
  font-size: 7vh;
}

.dateGnrlSizeInvitation {
  font-size: 4vh;
}

.flowers {
  height: 30vh;
}

.fTop {
  position: absolute;
  top: 0px;
  left: 0px;
}

.fDowm {
  position: absolute;
  bottom: -7px;
  right: 0px;
  rotate: 180deg;
  z-index: 0;
}

.welcome {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(15px + 2vmin);
  height: 95%;
  font-weight: 500;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.appFooter {
  padding-top: 5px;
  padding-bottom: 10px;
  font-size: 2vh;
  text-align: center;
}

@media only screen and (max-width:600px) {
  .appFooter {
    font-size: 1vh;
  }

  .welcome {
    flex-direction: column;
  }

  .App-logo {
    height: 60vmin;
    pointer-events: none;
  }

  .flowers {
    height: 20vh;
  }

  .mainLogo {
    margin-right: 20px;
  }

  .topLogo {
    font-size: 4.5vh;
  }
}

/*---Invitation Page----*/
.invitation {
  min-height: 100vh;
}

.frontInvitation {
  /*background-image: url('./assets/pattern.png');*/
  background-color: #FFFFFF;
  ;
}

.MenuDiv {
  position: -webkit-sticky;
  position: sticky;
  z-index: 5000;
  top: 0;
  background-color: #9D7F63;
}

.MainContentContainer {
  z-index: 1000;
}

@media only screen and (max-width:600px) {
  .MenuDiv {
    display: flex;
    justify-content: center;
  }
}

.stdAdress {
  font-size: medium;
}