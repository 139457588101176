/*--- DateCountdown ---*/
.dateHourContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.countdownTimer {
    margin-bottom: 10px;
}

.countdownTimer .values {
    width: 8vw;
    background-color: #fce0e5;
    display: inline-block;
    font-size: 3vh;
    margin: 5px;
    border-radius: 10%;
    text-align: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.countdownTimer .values .numbers {
    font-size: 3vh;
    font-weight: bold;
    text-align: center;
}

@media only screen and (max-width:600px) {
    .countdownTimer .values {
        width: 15vw;
        display: inline-block;
        font-size: 3vh;
        margin: 5px;
        border-radius: 10px;
        text-align: center;
    }

    .countdownTimer .values .numbers {
        font-size: 3vh;
        margin: 0px;
        font-weight: 400;
        text-align: center;
    }
}

.detailsDate {
    font-size: 4vh;
    padding-bottom: 3vh;
    text-align: center;
}

@media only screen and (max-width:600px) {
    .detailsDate {
        font-size: 7vw;
        padding-bottom: 1vh;
        text-align: center;
    }
}

/*---Photo---*/

.photoComponent {
    font-family: 'Aboreto';
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


@keyframes fadeIn1 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn1 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn1 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn1 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn1 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn2 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn2 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn2 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn2 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn2 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.photo {
    width: 100%;
    height: 80vh;
    object-fit: cover;
    object-position: 0 50%;
    /* animation: fadeIn 1000ms;
    -webkit-animation: fadeIn 1000ms;
    -moz-animation: fadeIn 1000ms;
    -o-animation: fadeIn 1000ms;
    -ms-animation: fadeIn 1000ms; */
}


@media only screen and (max-width:600px) {
    .photo {
        width: 100%;
        height: 68vh;
        object-position: 0 30%;
    }
}

.titleContainer {
    position: absolute;
    padding: 5px;
    top: 3vh;
    right: 10px;
    width: fit-content;
    height: fit-content;
    animation: fadeIn1 1000ms 700ms;
    -webkit-animation: fadeIn1 1000ms 700ms;
    -moz-animation: fadeIn1 1000ms 700ms;
    -o-animation: fadeIn1 1000ms 700ms;
    -ms-animation: fadeIn1 1000ms 700ms;
    opacity: 0;
    animation-fill-mode: forwards;
}

.titleContainer .title {
    color: beige;
    animation: fadeIn1 1000ms 1100ms;
    -webkit-animation: fadeIn1 1000ms 1100ms;
    -moz-animation: fadeIn1 1000ms 1100ms;
    -o-animation: fadeIn1 1000ms 1100ms;
    -ms-animation: fadeIn1 1000ms 1100ms;
    opacity: 0;
    animation-fill-mode: forwards;
}

.titleContainer {
    color: beige;
    font-weight: 300;
    animation: fadeIn2 900ms 1200ms;
    -webkit-animation: fadeIn2 900ms 1200ms;
    -moz-animation: fadeIn2 900ms 1200ms;
    -o-animation: fadeIn2 900ms 1200ms;
    -ms-animation: fadeIn2 900ms 1200ms;
    opacity: 0;
    animation-fill-mode: forwards;
}

@media only screen and (max-width:600px) {

    .titleContainer .title {
        text-shadow: 0 1px 1px #0b2c55;
    }
}

.PhotoContainer {
    position: -webkit-sticky;
    position: sticky;
    z-index: -1000;
    top: 0;
}

/*---VENUE---*/

.VenueComponent {
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.puntaLunaLogo {
    height: 10vh;
}

.photos {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.VenueTitle {
    font-size: 3vh;
    margin: 5px;
    padding: 0;
    text-align: center;
    font-weight: 600;
}

.VenueDescription {
    font-size: 2.5vh;
    font-weight: 400;
    text-align: center;
    margin: 5px;
}

.VenueData {
    font-size: 2.5vh;
    font-weight: 400;
    text-align: center;
}

.VenueDetails {
    font-size: 2.5vh;
    font-weight: 400;
    text-align: center;
}

.VenueDescriptionContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    align-items: center;
}

.itineraryandCarousel {
    width: 50%;
}

.carousel {
    display: flex;
    align-items: center;
}

.itinerarySection {
    display: flex;
}

@media only screen and (max-width:600px) {
    .VenueTitle {
        font-size: 3vh;
    }

    .VenueDescription {
        font-size: 3vh;
    }

    .VenueDescriptionContainer {
        width: 100%;
    }

    .itineraryandCarousel {
        width: 100%;
    }

    .itinerarySection {
        display: flex;
        flex-direction: column;
    }
}

.address {
    display: flex;
    width: 100%;
}

/*---MAP---*/
.map_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}

/*---Protocol---*/

.ProtocolComponent {
    padding: 10px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SectionTitle {
    font-size: 4vh;
    text-align: center;
    width: 60%;
}

.ProtocolDescription {
    font-size: 3vh;
}

.notAllow {
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 2.5vh;
    text-align: center;
}

.dresscode{
    display: flex;
    width: 50%;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
}

@media only screen and (max-width:600px) {
    .notAllow {
        width: 100%;
    }

    .womenDresscode{
        width: 100%;
    }

    .dresscodeSection {
        width: 100%;
    }

    .ProtocolDescription {
        font-size: 2.2vh;
    }

    .dresscode{
        width: 80%;
    }
}

/*---HOTELS---*/

.HotelsDescription {
    font-size: 2.5vh;
    font-weight: 400;
    text-align: center;
}

.HotelText {
    font-size: 2.5vh;
    font-weight: 400;
    text-align: center;
}

.HotelDetailsContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.HotelsSections {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.Hotels {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.HotelDivider {
    height: 30vh;
    display: flex;
    align-items: center;
}

@media only screen and (max-width:600px) {
    .HotelsSections {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .HotelDetailsContainer {
        width: 100%;
    }
}


/*---Quote---*/

.QuoteText {
    text-shadow: #bfd7ea 1px 0 10px;
    font-size: 3vh;
    text-align: center;
    font-weight: 400;
}

.QuoteAuthor {
    font-size: 2.5vh;
    text-align: center;
}

.QuoteDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
}

.QuoteLogo {
    height: 40vmin;
    pointer-events: none;
    align-self: center;
    margin-left: 10px;
    margin-right: 10px;
}

@media only screen and (max-width:600px) {
    .QuoteText {
        font-size: 2.4vh;
    }

    .QuoteAuthor {
        font-size: 1.5vh;
    }
}

/*---Gift---*/

.CardComponent {
    padding: 20px;
    margin: 0px;
    background-color: #fce0e5;
    min-height: 35vh;
    width: 25vw;
    border-radius: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.GiftLogo {
    height: 30vmin;
    pointer-events: none;
    align-self: center;
}

.GiftText {
    text-align: center;
    margin: 5px;
    font-size: 2.3vh;
}

.CardsSection {
    display: flex;
    justify-content: space-evenly;
}

.GiftTitle {
    font-size: 3vh;
}

.giftsThanks {
    font-size: 2.5vh;
    text-align: center;
}

.giftButtons {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-content: space-around;
    height: 20vh;
}

@media only screen and (max-width:600px) {
    .CardsSection {
        flex-direction: column;
        align-items: center;
    }

    .CardComponent {
        width: 75vw;
        margin-top: 10px;
    }
}

/*---RSVP---*/

.rsvp {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
}

.rsvpCard {
    border-radius: 5% !important;
    justify-content: space-evenly;
    width: 30vw !important;
    padding-left: 25px;
    padding-right: 25px;
}

.rsvpNames{
    font-family: 'Daydream';
    font-size: 7vh;
}

.whatsIcon {
    height: 3vh;
}

.rsvpSection {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width:600px) {
    .rsvpCard {
        width: 75vw !important;
    }

}

/*---  Dynamic Title---*/

.dynamicTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.dynamicImage {
    height: 10vh;
}

.dynamicImageRotate {
    rotate: 180deg;
}